.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* styles.css */

.container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  min-height: 100vh;
}

.formContainer {
  min-width: 350px;
  height: fit-content;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 2rem;
}

.clipsContainer {
  width: 75%;
  padding: 10px;
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.inputField {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: border-color 0.2s ease;
}

.inputField:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.button {
  background-color: #3498db;
  color: white;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: #2980b9;
}

.clipsGrid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  display: grid;
  gap: 16px;
  justify-items: center;
}

.clipCard {
  max-width: 250px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.clipImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.clipDescription {
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.inputTable {
  width: 100%;
}

.inputRow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.inputLabel {
  font-size: 0.9rem;
  font-weight: 500;
  color: #34495e;
}

.inputField {
  flex-grow: 1;
}
.clipOverlay {
  position: relative;
}

.clipDescriptionOverlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  color: white;
  width: 100%;
  padding: 0px;
}

.section-header {
  font-size: 1.25rem;
  color: #2c3e50;
  font-weight: 600;
  margin: 1.5rem 0 1rem 0;
  padding-bottom: 0.5rem;
}

.toggleButtonContainer {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  padding: 0.5rem;
  background-color: #f8fafc;
  border-radius: 8px;
}

.toggleButton {
  flex: 1;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: transparent;
}

.toggleButton.active {
  background-color: #2ecc71;
  color: white;
}

.toggleButton:hover:not(.active) {
  background-color: #e2e8f0;
}

.stories-grid {
  display: flex;
  gap: 2rem;
  padding: 1rem 1rem 2rem;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.stories-grid::-webkit-scrollbar {
  height: 12px;
  display: block;
  background-color: #e2e8f0;
}

.stories-grid::-webkit-scrollbar-track {
  background: #e2e8f0;
  border-radius: 6px;
}

.stories-grid::-webkit-scrollbar-thumb {
  background-color: #94a3b8;
  border-radius: 6px;
  border: 2px solid #e2e8f0;
  min-width: 40px;
}

.stories-grid::-webkit-scrollbar-thumb:hover {
  background-color: #64748b;
}

.storyCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  scroll-snap-align: start;
}

.storyImageContainer {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  margin-bottom: 1rem;
  border: 3px solid #ffffff;
}

.storyCard:hover .storyImageContainer {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.storyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.storyTitle {
  font-size: 1rem;
  font-weight: 500;
  color: #1a202c;
  margin-bottom: 1rem;
  line-height: 1.4;
  text-align: center;
  max-width: 280px;
}

.storyContent {
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.infoBox {
  background-color: #f8fafc;
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: 0.75rem;
  font-size: 0.875rem;
  color: #4a5568;
  width: 100%;
  box-sizing: border-box;
}

.infoBox:first-child {
  margin-top: 0;
}

.infoBoxHeader {
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #2d3748;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.categoryItem {
  display: inline-flex;
  align-items: center;
  background-color: #e2e8f0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  color: #4a5568;
  margin: 0;
  white-space: nowrap;
}

.categoriesContainer {
  max-height: 100px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.3s ease;
}

.categoriesContainer.expanded {
  max-height: 1000px;
}

.categoriesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  padding-bottom: 24px;
}

.expandButton {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(248, 250, 252, 0) 0%, #f8fafc 50%);
  padding: 20px 0 5px;
  text-align: center;
  cursor: pointer;
  font-size: 0.75rem;
  color: #3498db;
  font-weight: 500;
}

.expandButton:hover {
  color: #2980b9;
}

.showMoreButton {
  background-color: transparent;
  color: #3498db;
  border: none;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 500;
}

.showMoreButton:hover {
  color: #2980b9;
}

.storiesContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.storiesHeaderSection {
  display: flex;
  gap: 2rem;
  padding: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: thin;
  scrollbar-color: #94a3b8 #e2e8f0;
}

/* Scrollbar styling for header section */
.storiesHeaderSection::-webkit-scrollbar {
  height: 8px;
  display: block;
}

.storiesHeaderSection::-webkit-scrollbar-track {
  background: #e2e8f0;
  border-radius: 4px;
}

.storiesHeaderSection::-webkit-scrollbar-thumb {
  background-color: #94a3b8;
  border-radius: 4px;
}

.storiesHeaderSection::-webkit-scrollbar-thumb:hover {
  background-color: #64748b;
}

.storyHeaderCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  scroll-snap-align: start;
}

/* Remove scrollbar from stories-grid */
.stories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  width: 100%;
}

/* For Firefox */
@-moz-document url-prefix() {
  .stories-grid {
    scrollbar-width: auto;
    scrollbar-color: #94a3b8 #e2e8f0;
  }
}

/* Prevent dragging of images and other elements */
.storyCard * {
  user-drag: none;
  -webkit-user-drag: none;
}

/* Add a draggable indicator container */
.stories-grid-container {
  position: relative;
  margin: 1rem 0;
}

/* Add subtle animation */
@keyframes fadeInOut {
  0%, 100% { opacity: 0.4; }
  50% { opacity: 0.8; }
}

/* Add a subtle hover state */
.stories-grid:hover {
  box-shadow: 0 0 0 2px rgba(148, 163, 184, 0.1);
}

/* Thumbnail hover animation */
.storyImageContainer {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 3px solid #ffffff;
  position: relative;
}

.storyImageContainer:hover {
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.liveIndicator {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff0000;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

/* Pulse animation for new stories */
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(52, 152, 219, 0); }
  100% { box-shadow: 0 0 0 0 rgba(52, 152, 219, 0); }
}

/* Card entrance animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.storyCard {
  animation: slideIn 0.5s ease-out forwards;
  animation-delay: calc(var(--index) * 0.1s);
  opacity: 0;
}

/* Info box hover effect */
.infoBox {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.infoBox:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Category item hover animation */
.categoryItem {
  transition: all 0.2s ease;
}

.categoryItem:hover {
  background-color: #cbd5e1;
  transform: translateY(-1px);
}

